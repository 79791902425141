import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { BackendError, useCollection } from '@skywatch/core'
import { TrashIcon, Button, PlusIcon, useDialog, Alert, EyeIcon } from '@skywatch/ui'
import { Wait, useErrorModal } from '@drive/shared'
import brokerCollection from './store/broker.collection'
import { useAffiliate, useBrokerDialog } from './hooks'
import { Broker } from './store/types/broker'
import BrokersList from './components/BrokersList'
import AffiliateInformation from './components/AffiliateInformation'
import BackHeader from './components/BackHeader'

export default () => {
  const navigate = useNavigate()
  const affiliateResource = useAffiliate()
  const { affiliateId } = useParams()
  const brokers = useCollection(brokerCollection)
  const selectedBroker = brokers.getSelected()
  const { setOpen: setDeleteBrokerOpen, Dialog: DeleteBrokerPopup, open: deleteBrokerPopupOpen } = useDialog()
  const { BrokerPopup: AddBrokerPopup, setOpen: setBrokerOpen } = useBrokerDialog()
  const [error, setError] = useState<BackendError>()
  const { setOpen: setOpenErrorModal, ErrorModal } = useErrorModal()

  useEffect(() => {
    affiliateResource.reset()
    affiliateResource.fetchAffiliateByKey(affiliateId!)
    const fetchBrokers = async () => {
      brokers.reset()
      try {
        await brokers.fetchAllBrokers(affiliateId!)
      } catch (e: any) {
        setError({ message: e.messages[0].messages[0].message, title: e.messages[0].messages[0].payload })
        setOpenErrorModal(true)
      }
    }
    fetchBrokers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affiliateId])

  useEffect(() => {
    if (!deleteBrokerPopupOpen) {
      brokers.select(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteBrokerPopupOpen])

  return (
    <main className=" flex h-[calc(100vh-60px)] w-full flex-col items-center bg-[#F2F2F2]">
      <div className="flex w-2/3 flex-col ">
        <div className="flex w-full">
          <BackHeader
            onBack={() => navigate(-1)}
            text="Agency Details"
            icon={<EyeIcon className="stroke-black stroke-2" />}
          />
        </div>
        <div
          className={`flex w-full flex-col gap-8 self-center rounded-xl bg-white px-8 py-6 ${
            affiliateResource.state.disabled && 'pointer-events-none opacity-50'
          }`}
        >
          <AffiliateInformation />
          <BrokersList
            brokers={brokers.items}
            onDelete={(broker: Broker) => {
              brokers.select(broker.id)
              setDeleteBrokerOpen(true)
            }}
            onEdit={(broker: Broker) => {
              brokers.select(broker.id)
              setBrokerOpen(true)
            }}
          />

          <Button
            onClick={() => setBrokerOpen(true)}
            buttonType="primary"
            variant="outline"
            className="text-primary-500 mb-2.5 w-fit px-5 text-sm font-semibold "
          >
            <PlusIcon className="stroke-primary-500 mr-2 stroke-2" />
            Add a New Agent
          </Button>
        </div>
      </div>
      <AddBrokerPopup />
      <ErrorModal errorText={error?.message || undefined} errorTitle={error?.title} />
      {selectedBroker && (
        <DeleteBrokerPopup>
          <Alert
            icon={<TrashIcon className="stroke-primary-500 stroke-2" />}
            onClose={() => {
              setDeleteBrokerOpen(false)
              brokers.select(null)
            }}
          >
            <div className="mt-4 font-semibold ">Are you sure that you want to remove this agent?</div>
            <div className="mt-8 grid grid-cols-2 gap-3">
              <Button
                buttonType={'gray'}
                variant={'outline'}
                className="col-span-2 w-full md:col-span-1"
                onClick={() => {
                  setDeleteBrokerOpen(false)
                  brokers.select(null)
                }}
              >
                Cancel
              </Button>
              <Button
                buttonType={'primary'}
                variant={'standard'}
                className="col-span-2 w-full md:col-span-1"
                onClick={() => {
                  brokers.deleteBroker(selectedBroker.id)
                  setDeleteBrokerOpen(false)
                  brokers.select(null)
                }}
              >
                Remove
              </Button>
            </div>
          </Alert>
        </DeleteBrokerPopup>
      )}
      <Wait visible={!affiliateId || brokers.isBusy || affiliateResource.state.status === 'busy'} />
    </main>
  )
}
