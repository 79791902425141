import { SVGAttributes, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as E from 'effect/Either'
import { pipe } from 'effect/Function'
import { DotsIcon, DropdownMenu, EditIcon, KeyIcon, PlusIcon } from '@skywatch/ui'
import { useSession } from '@drive/auth'
import { useAffiliate, useDeleteAffiliateDialog, useEditAffiliateDialog, useGenerateApiKeyDialog } from '../hooks'
import { generateApiKeyEndpoint } from '~/api/affiliate'
import { Wait } from '@drive/shared'

type Action = {
  key: string
  text: string
  icon: (props: SVGAttributes<SVGSVGElement>) => JSX.Element
  action: () => void
}

export default () => {
  const navigate = useNavigate()
  const { state: affiliate } = useAffiliate()
  const {
    state: { permissions },
  } = useSession()
  const { EditAffiliatePopup, setOpen: setEditAffiliateOpen } = useEditAffiliateDialog()
  const { DeleteAffiliatePopup } = useDeleteAffiliateDialog({ affiliate: affiliate })
  const { GenerateApiKeyPopup, setOpen: setGenerateApiKeyOpen } = useGenerateApiKeyDialog()
  const [loading, setLoading] = useState(false)
  const [apiKey, setApiKey] = useState<string>()

  const handleApiKeyGeneration = async () => {
    setLoading(true)
    const res = await generateApiKeyEndpoint({ payload: {}, urlParams: { affiliateKey: affiliate.id } })
    pipe(
      res,
      E.match({
        onLeft: error => {
          throw error
        },
        onRight: apiResponse => {
          setApiKey(apiResponse.data.apiKey)
        },
      })
    )
    setLoading(false)
    setGenerateApiKeyOpen(true)
  }

  const actions: Action[][] = [
    [
      {
        key: 'editAgencyDetails',
        text: 'Edit Agency Details',
        icon: (props: SVGAttributes<SVGSVGElement>) => <EditIcon className={` ${props.className}`} />,
        action: () => setEditAffiliateOpen(true),
      },
      {
        key: 'addSubAgency',
        text: 'Add Sub-Agency',
        icon: (props: SVGAttributes<SVGSVGElement>) => <PlusIcon className={`!stroke-2 ${props.className}`} />,
        action: () => navigate(`/affiliates/new?parentKey=${affiliate.id}&parentName=${affiliate.name}`),
      },
      {
        key: 'generateApiKey',
        text: 'Generate API Key',
        icon: (props: SVGAttributes<SVGSVGElement>) => <KeyIcon className={`${props.className}  !stroke-2 `} />,
        action: () => handleApiKeyGeneration(),
      },
    ].filter(a => permissions.includes(a.key)),
  ]

  return (
    <div className="flex flex-row justify-between">
      <div>
        <div className="text-2xl font-semibold">{affiliate.name}</div>
        <div className="my-1 text-gray-600">{affiliate.description}</div>
      </div>
      <div className="flex items-center">
        <DropdownMenu items={actions} className="z-10 flex w-max stroke-black !ring-gray-200">
          <DotsIcon className="mr-2 stroke-gray-400 stroke-2" />
        </DropdownMenu>
      </div>
      <EditAffiliatePopup />
      <DeleteAffiliatePopup />
      {apiKey && <GenerateApiKeyPopup apiKey={apiKey} />}
      <Wait visible={loading} />
    </div>
  )
}
