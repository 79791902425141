import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, CloseIcon, EmailIcon, Form, IconWrapper, useDialog, UserPlusIcon } from '@skywatch/ui'
import {
  createApplication,
  getUserDeclinationsEndpoint,
  States,
  useErrorModal,
  useStateNotAvailable,
  USStateTypes,
  Wait,
} from '@drive/shared'
import { adminCreateUser } from '~/api/user'
import { NewUser, NewUserSchema } from '~/models/newUser'
import { BackendError } from '@skywatch/core'

const getDeclinations = async (userId: number): Promise<boolean> => {
  const { data } = await getUserDeclinationsEndpoint(null, { pathParams: { userId } })
  if (data.result.length > 0 && data.result[0].key === 'StateNotSupported') {
    return true
  }
  return false
}

export default () => {
  const navigate = useNavigate()
  const { setOpen, Dialog } = useDialog()
  const [loading, setLoading] = useState(false)
  const [selectedState, setSelectedState] = useState<string>('')
  const [error, setError] = useState<BackendError>()
  const { setOpen: setOpenErrorModal, ErrorModal } = useErrorModal()
  const { setOpen: setOpenStateNotAvailable, StateNotAvailableDialog } = useStateNotAvailable()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NewUser>({
    resolver: zodResolver(NewUserSchema),
  })

  const onSubmit = async (values: NewUser) => {
    setLoading(true)
    try {
      // create a new user
      const { id: userId } = await adminCreateUser({ username: values.email, state: values.state })

      //check if the state is supported
      const isDelination = await getDeclinations(userId)
      if (isDelination) {
        setLoading(false)
        setOpen(false)
        setSelectedState(States[values.state as USStateTypes])
        setOpenStateNotAvailable(true)
        return
      }

      await createApplication(null, {
        pathParams: { userId },
      })
      navigate(`/user/${userId}/insurance`)
    } catch (e: any) {
      if (e.code === 409) {
        try {
          const userId = e.messages[0].messages[0].payload.userId
          await createApplication(null, {
            pathParams: { userId },
          })
          navigate(`/user/${userId}/insurance`)
        } catch (ex: any) {
          if (ex.code === 422) {
            setError({ message: e.messages[0].messages[0].message, title: 'Action is not allowed' })
            setOpenErrorModal(true)
          } else {
            throw ex
          }
        }
      } else {
        if (e.code === 403) {
          setError({ message: e.messages[0].messages[0].message, title: 'Action is not allowed' })
          setOpenErrorModal(true)
        } else {
          throw e
        }
      }
    } finally {
      setLoading(false)
      setOpen(false)
    }
  }

  const CreateUserDialog = () => {
    return (
      <>
        <ErrorModal errorText={error?.message} errorTitle={error?.title} />
        <StateNotAvailableDialog state={selectedState} />
        <Dialog>
          <div className="flex justify-between">
            <div className="relative mb-2">
              <IconWrapper className="absolute" type="primary">
                <UserPlusIcon className="stroke-primary-500 stroke-2" />
              </IconWrapper>
            </div>
            <div>
              <button onClick={() => setOpen(false)}>
                <CloseIcon className="stroke-gray-500 stroke-2" />
              </button>
            </div>
          </div>
          <div className="mb-1 text-lg font-semibold">Initiate a New Quote</div>
          <div className="mb-5 text-gray-500">Please insert customer email</div>
          <form
            id="create_user"
            onSubmit={handleSubmit(onSubmit)}
            className="flex shrink flex-col items-center  bg-transparent md:items-start md:justify-start md:gap-x-5 md:rounded-b-2xl md:bg-white"
          >
            <div className="flex w-full flex-col-reverse bg-transparent md:flex-row md:space-x-2">
              {/* email  */}
              <Form.Control className=" w-full md:mt-0 md:w-3/4">
                <Form.Label className="hidden h-6 md:block">Email</Form.Label>
                <Form.Input
                  className={`input-small text-sm ${errors?.email && 'input-error'}`}
                  {...register('email')}
                  placeholder="Email"
                  icon={<EmailIcon className={`${errors?.email ? 'stroke-error-400' : 'stroke-black'}`} />}
                />
                <Form.Error className={` ${errors?.email ? 'visible' : 'invisible'}`}>
                  {errors?.email?.message}
                </Form.Error>
              </Form.Control>

              {/* state  */}
              <Form.Control className="w-1/2 md:w-1/4">
                <Form.Label className="hidden h-6 md:block">State</Form.Label>
                <Form.Select
                  className={`input-small text-sm text-gray-900 ${errors?.state && 'input-error'}`}
                  {...register('state')}
                >
                  {Object.entries(States).map(([v, k]) => (
                    <option key={v} value={v}>
                      {k}
                    </option>
                  ))}
                </Form.Select>
                <Form.Error className={` ${errors?.state ? 'visible' : 'invisible'}`}>
                  {errors?.state?.message}
                </Form.Error>
              </Form.Control>
            </div>
          </form>
          <div className="mt-3 grid grid-cols-6 gap-3">
            <Button buttonType={'gray'} variant={'outline'} className="col-span-3" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button buttonType={'primary'} variant={'standard'} className="col-span-3" type="submit" form="create_user">
              Continue
            </Button>
          </div>
          <Wait visible={loading} />
        </Dialog>
      </>
    )
  }

  return { setOpen, CreateUserDialog }
}
