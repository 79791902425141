import { z } from 'zod'
import BrokerDetails from './broker'

const AffiliatePaymentFees = z
  .object({
    registrationFee: z.boolean(),
    quoteFee: z.boolean(),
    bindCommission: z.boolean().optional(),
    newBusinessCommission: z.number().min(0).max(15).optional(),
    renewalCommission: z.number().min(0).max(15).optional(),
  })
  .refine(data => data.registrationFee || data.quoteFee || data.bindCommission, {
    message: 'At least one payment option must be selected.',
    path: [''],
  })

export type AffiliatePaymentFeesType = z.infer<typeof AffiliatePaymentFees>

const Affiliate = z.object({
  description: z.string().trim().optional(),
  name: z.string().trim().min(1, { message: 'Required' }),
  network: z.string().optional(),
  admin: BrokerDetails,
  paymentFees: AffiliatePaymentFees.optional(),
})

export type AffiliateType = z.infer<typeof Affiliate>

export { Affiliate, AffiliatePaymentFees }
