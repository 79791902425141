import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useToken } from '@drive/auth'
import { Wait } from '@drive/shared'

export default () => {
  const [params] = useSearchParams()
  const navigate = useNavigate()
  const tokenData = useToken()

  const handleRedirect = async () => {
    const url = params.get('url')
    if (!url) {
      navigate('/auth/login')
    } else {
      if (tokenData) {
        navigate(url)
      } else {
        localStorage.setItem('redirectUrl', url)
        navigate('/auth/login')
      }
    }
  }

  useEffect(() => {
    handleRedirect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Wait />
}
