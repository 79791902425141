import { Alert, Button, CheckIcon, CirclesFadeIcon, CopyIcon, KeyIcon, useDialog } from '@skywatch/ui'
import { useState } from 'react'

const GenerateApiKeyPopup = ({ setOpen, apiKey }: { setOpen: (isOpen: boolean) => void; apiKey: string }) => {
  const [copied, setCopied] = useState(false)

  const handleCopy = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    navigator.clipboard.writeText(apiKey)
    setCopied(true)
  }

  return (
    <>
      <CirclesFadeIcon className="absolute left-2 top-2.5 -z-10 pl-1" />
      <Alert onClose={() => setOpen(false)} icon={<KeyIcon className="stroke-gray-600 stroke-2" />} type="gray">
        <div className="font-poppins mt-3 text-lg font-medium leading-6 text-gray-900">
          Your API Key Has Been Generated!
        </div>
        <div className="font-poppins mt-1 text-sm font-light leading-6 text-gray-600">
          This is a one-time API key. You can generate a new key later, <b>but this key won’t appear again.</b>
        </div>
        <div className="mt-3 flex flex-row justify-between rounded-lg bg-gray-50 px-3 py-2">
          <div className="font-poppins text-sm font-normal">{apiKey}</div>
          <KeyIcon className="h-4 w-4 stroke-gray-500 stroke-2" />
        </div>
        <div className="mt-6 grid gap-3">
          <Button
            disabled={copied}
            buttonType="primary"
            variant="standard"
            type="button"
            className={`md:col-span-1ֿ col-span-2 w-full ${copied && 'opacity-90'}`}
            onClick={handleCopy}
          >
            <div>{copied ? 'Copied Successfully' : 'Copy Key to Clipboard'}</div>
            {copied ? <CheckIcon className="stroke-white stroke-2" /> : <CopyIcon className="stroke-white stroke-2" />}
          </Button>
        </div>
      </Alert>
    </>
  )
}

export default () => {
  const { setOpen, Dialog } = useDialog()

  return {
    GenerateApiKeyPopup: ({ apiKey }: { apiKey: string }) => (
      <Dialog>
        <GenerateApiKeyPopup apiKey={apiKey} setOpen={setOpen} />
      </Dialog>
    ),
    setOpen,
  }
}
