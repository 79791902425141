import * as E from 'effect/Either'
import { pipe } from 'effect/Function'
import { createResource } from '@skywatch/core'
import {
  createAffiliateEndpoint,
  createAffiliateWithParentEndpoint,
  disableAffiliateEnpoint,
  getAffiliateByKeyEndpoint,
  updateAffiliateEndpoint,
  updateAffiliatePaymentFeesEndpoint,
} from '~/api/affiliate'
import { Affiliate } from './types/affiliate'
import { AffiliatePaymentFeesType, AffiliateType } from '../forms/types/affiliate'

const initialState: Affiliate = {
  id: '',
  name: '',
  parentsKeys: [],
  disabled: false,
  url: '',
  logoUrl: '',
  description: '',
  registrationFee: false,
  qouteFee: false,
  newBusinessCommission: 0,
  renewalCommission: 0,
}

const createAffiliate = async (affiliateDetails: AffiliateType) => {
  const apiAffiliate = Affiliate.toPostApi(affiliateDetails)

  if (apiAffiliate.parentKey) {
    const res = await createAffiliateWithParentEndpoint({
      payload: apiAffiliate,
      urlParams: { affiliateKey: apiAffiliate.parentKey },
    })
    return pipe(
      res,
      E.match({
        onLeft: error => {
          throw error
        },
        onRight: apiResponse => {
          const affiliateKey = apiResponse.data.affiliateKey
          return {
            id: affiliateKey,
            name: apiAffiliate.affiliateName,
            description: apiAffiliate.description,
            logoUrl: apiAffiliate.logoUrl,
            parentsKeys: [apiAffiliate.parentKey],
            url: '',
            disable: false,
          }
        },
      })
    )
  }
  const res = await createAffiliateEndpoint({ payload: apiAffiliate })

  const re = pipe(
    res,
    E.match({
      onLeft: error => {
        throw error
      },
      onRight: apiResponse => {
        const affiliateKey = apiResponse.data.affiliateKey
        return {
          id: affiliateKey,
          name: apiAffiliate.affiliateName,
          description: apiAffiliate.description,
          logoUrl: apiAffiliate.logoUrl,
          parentsKeys: [apiAffiliate.parentKey],
          url: '',
        }
      },
    })
  )
  return re
}

const fetchAffiliateByKey = async (key: string): Promise<Affiliate> => {
  const res = await getAffiliateByKeyEndpoint({ urlParams: { affiliateKey: key } })

  return pipe(
    res,
    E.match({
      onLeft: error => {
        throw error
      },
      onRight: apiResponse => {
        return Affiliate.fromApi(apiResponse.data)
      },
    })
  )
}

const updateAffiliate = async (affiliate: Affiliate, affiliateUpdated: AffiliateType) => {
  const res = await updateAffiliateEndpoint({
    urlParams: { affiliateKey: affiliate.id },
    payload: { affiliateName: affiliateUpdated.name, description: affiliateUpdated.description },
  })

  return pipe(
    res,
    E.match({
      onLeft: error => {
        throw error
      },
      onRight: _ => {
        return {
          id: affiliate.id,
          name: affiliateUpdated.name,
          description: affiliateUpdated.description,
          parentsKeys: [affiliate.parentsKeys],
          logoUrl: '',
          url: '',
          disabled: false,
        }
      },
    })
  )
}

const deactivateAffiliate = async (key: string) => {
  const res = await disableAffiliateEnpoint({ urlParams: { affiliateKey: key } })

  return pipe(
    res,
    E.match({
      onLeft: error => {
        throw error
      },
      onRight: () => {
        return key
      },
    })
  )
}

const updateAffiliatePaymentFees = async (affiliateId: string, paymentFees: AffiliatePaymentFeesType) => {
  const res = await updateAffiliatePaymentFeesEndpoint({
    urlParams: { affiliateKey: affiliateId },
    payload: {
      registrationFee: paymentFees.registrationFee,
      quoteFee: paymentFees.quoteFee,
      bindCommission: paymentFees.bindCommission,
      newBusinessCommission: paymentFees.newBusinessCommission ?? 0,
      renewalCommission: paymentFees.renewalCommission ?? 0,
    },
  })

  return pipe(
    res,
    E.match({
      onLeft: error => {
        throw error
      },
      onRight: apiResponse => {
        return apiResponse.data
      },
    })
  )
}

export default createResource({
  name: 'affiliate',
  initialState,
  asyncMethods: {
    fetchAffiliateByKey,
    updateAffiliate,
    deactivateAffiliate,
    createAffiliate,
    updateAffiliatePaymentFees,
  },
})
