import { DefaultValues, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { EmailIcon, Form, PercentIcon, PhoneIcon, UserIcon } from '@skywatch/ui'
import { Affiliate, AffiliateType } from './types/affiliate'
import { useEffect } from 'react'

type AffiliateNewFormProps = {
  onSubmitForm: (data: AffiliateType) => void
  defaultValues?: DefaultValues<AffiliateType>
  parentName?: string | null
  isAdmin: boolean
  isEditMode: boolean
}

export default ({ onSubmitForm, defaultValues, parentName, isAdmin, isEditMode }: AffiliateNewFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    unregister,
    getValues,
    trigger,
  } = useForm<AffiliateType>({
    resolver: zodResolver(
      isEditMode
        ? Affiliate.omit({
            admin: true,
            network: true,
          })
        : Affiliate
    ),
    defaultValues: defaultValues || {},
  })

  const bindChecked = getValues('paymentFees.bindCommission')

  useEffect(() => {
    if (!bindChecked) {
      unregister('paymentFees.newBusinessCommission')
      unregister('paymentFees.renewalCommission')
    }
  }, [bindChecked])

  return (
    <>
      <form id="affiliate-form" onSubmit={handleSubmit(onSubmitForm)}>
        <Form.Grid>
          <div className="!col-span-6 text-xl font-semibold">{isEditMode && 'Edit '}Agency details</div>
          {/* Agency Name */}
          <Form.Control className={`${isEditMode ? '!col-span-6' : '!col-span-3'}`}>
            <Form.Label className="!mt-0">Agency Name</Form.Label>
            <Form.Input
              className={`input ${errors.name && 'input-error'}`}
              {...register('name')}
              placeholder="Agency name"
            />
            <Form.Error className={`${!errors.name?.message && 'hidden'}`}>{errors.name?.message}</Form.Error>
          </Form.Control>

          {/* Network */}
          {!isEditMode && (
            <Form.Control className="!col-span-3">
              <Form.Label className="!mt-0">Network</Form.Label>
              <Form.Input disabled={true} className="input" placeholder={`${parentName ? parentName : 'None'}`} />
            </Form.Control>
          )}

          {/* Description */}
          <Form.Control className="md:!col-span-6">
            <Form.Label className="!mt-0">Description</Form.Label>
            <Form.Input
              className={`input ${errors.description && 'input-error'}`}
              {...register('description')}
              placeholder="e.g : Gallagher Drone Insurance powered by SkyWatch.AI"
            />
            <Form.Error className={`${!errors.description?.message && 'hidden'}`}>
              {errors.description?.message}
            </Form.Error>
          </Form.Control>

          {!isEditMode && (
            <>
              <div className="!col-span-6 mt-3 text-sm font-medium">Primary Agent details</div>
              {/* First Name */}
              <Form.Control className="md:!col-span-3">
                <Form.Label className="!mt-0">First name</Form.Label>
                <Form.Input
                  icon={
                    <UserIcon className={`${errors.admin?.firstName?.message ? 'stroke-error-400' : 'stroke-black'}`} />
                  }
                  className={`input ${errors.admin?.firstName && 'input-error'}`}
                  {...register('admin.firstName')}
                  placeholder="First name"
                />
                <Form.Error className={`${!errors.admin?.firstName?.message && 'hidden'}`}>
                  {errors.admin?.firstName?.message}
                </Form.Error>
              </Form.Control>
              {/* Last Name */}
              <Form.Control className="md:!col-span-3">
                <Form.Label className="!mt-0">Last name</Form.Label>
                <Form.Input
                  icon={
                    <UserIcon className={`${errors.admin?.lastName?.message ? 'stroke-error-400' : 'stroke-black'}`} />
                  }
                  className={`input ${errors.admin?.lastName && 'input-error'}`}
                  {...register('admin.lastName')}
                  placeholder="Last name"
                />
                <Form.Error className={`${!errors.admin?.lastName?.message && 'hidden'}`}>
                  {errors.admin?.lastName?.message}
                </Form.Error>
              </Form.Control>

              {/* Email Name */}
              <Form.Control className="md:!col-span-3">
                <Form.Label className="!mt-0">Email</Form.Label>
                <Form.Input
                  icon={
                    <EmailIcon className={`${errors.admin?.email?.message ? 'stroke-error-400' : 'stroke-black'}`} />
                  }
                  className={`input ${errors.admin?.email && 'input-error'}`}
                  {...register('admin.email')}
                  placeholder="Email"
                />
                <Form.Error className={`${!errors.admin?.email?.message && 'hidden'}`}>
                  {errors.admin?.email?.message}
                </Form.Error>
              </Form.Control>

              {/* phone number  */}
              <Form.Control className="md:!col-span-3">
                <Form.Label className="!mt-0">Phone number</Form.Label>
                <Form.Input
                  icon={
                    <PhoneIcon
                      className={`${errors.admin?.phoneNumber?.message ? 'stroke-error-400' : 'stroke-black'}`}
                    />
                  }
                  className={`input ${errors.admin?.phoneNumber && 'input-error'}`}
                  {...register('admin.phoneNumber')}
                  placeholder="Phone number"
                />
                <Form.Error className={`${!errors.admin?.phoneNumber?.message && 'hidden'}`}>
                  {errors.admin?.phoneNumber?.message}
                </Form.Error>
              </Form.Control>
            </>
          )}
          {isAdmin && (
            <>
              <div className="!col-span-6 text-sm font-medium">Agency Payment Method</div>
              {/* Registration  */}
              <Form.Control className="md:!col-span-6 ">
                <Form.Checkbox
                  className={`text-sm font-normal text-gray-900 md:col-span-6 ${
                    errors.paymentFees?.message && 'checkbox-error'
                  }`}
                  {...register('paymentFees.registrationFee', {
                    onChange: () => trigger('paymentFees'),
                  })}
                >
                  Per registration
                </Form.Checkbox>
              </Form.Control>
              {/* Quote  */}
              <Form.Control className="md:!col-span-6">
                <Form.Checkbox
                  className={`text-sm font-normal text-gray-900 md:col-span-6 ${
                    errors.paymentFees?.message ? 'checkbox-error' : ''
                  }`}
                  {...register('paymentFees.quoteFee', {
                    onChange: () => trigger('paymentFees'),
                  })}
                >
                  Per quote
                </Form.Checkbox>
              </Form.Control>
              {/* bind  */}
              <Form.Control className="md:!col-span-6">
                <Form.Checkbox
                  className={`text-sm font-normal text-gray-900 md:col-span-6 ${
                    errors.paymentFees?.message && 'checkbox-error'
                  }`}
                  {...register('paymentFees.bindCommission', {
                    onChange: () => trigger('paymentFees'),
                  })}
                >
                  Per bind
                </Form.Checkbox>
                <Form.Error className={`${!errors.paymentFees?.message && 'hidden'}`}>
                  {errors.paymentFees?.message}
                </Form.Error>
              </Form.Control>

              {bindChecked && (
                <>
                  <div className="!col-span-6 text-sm font-medium">Commission Compensation</div>
                  <Form.Control className="md:!col-span-3">
                    <Form.Label className="!mt-0 !pt-0">Per New Business</Form.Label>
                    <Form.Input
                      step="0.01"
                      type="number"
                      className={`input ${errors.paymentFees?.newBusinessCommission && 'input-error'}`}
                      {...register('paymentFees.newBusinessCommission', {
                        valueAsNumber: true,
                      })}
                      icon={
                        <PercentIcon
                          className={`${
                            errors.paymentFees?.newBusinessCommission?.message ? 'stroke-error-400' : 'stroke-black'
                          }`}
                        />
                      }
                    />
                    <Form.Error
                      className={`${!errors.paymentFees?.newBusinessCommission?.message && '!text-gray-600'}`}
                    >
                      Percentage between 0-15%
                    </Form.Error>
                  </Form.Control>

                  <Form.Control className="md:!col-span-3">
                    <Form.Label className="!mt-0 !py-0">Per Renewal</Form.Label>
                    <Form.Input
                      type="number"
                      step="0.01"
                      className={`input ${errors.paymentFees?.renewalCommission && 'input-error'}`}
                      {...register('paymentFees.renewalCommission', {
                        valueAsNumber: true,
                      })}
                      icon={
                        <PercentIcon
                          className={`${
                            errors.paymentFees?.renewalCommission?.message ? 'stroke-error-400' : 'stroke-black'
                          }`}
                        />
                      }
                    />
                    <Form.Error className={`${!errors.paymentFees?.renewalCommission?.message && '!text-gray-600'}`}>
                      Percentage between 0-15%
                    </Form.Error>
                  </Form.Control>
                </>
              )}
            </>
          )}
        </Form.Grid>
      </form>
    </>
  )
}
