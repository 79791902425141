import { useParams } from 'react-router-dom'
import { Page } from '@skywatch/ui'
import { useSession } from '@drive/auth'
import { PageTitle } from '@drive/shared'
import { Header } from '~/components'
import Policies from './Policies'
import Odometers from './Odometers'
import { Provider } from './store/context'
import QuoteManager from './QuoteManager'

const DashboardPage = () => {
  const { tab } = useParams()
  const session = useSession()

  const getSelectedGrid = () => {
    switch (tab) {
      case 'policies': {
        return <Policies />
      }
      case 'odometers': {
        return <Odometers />
      }
      default: {
        return <QuoteManager />
      }
    }
  }

  return (
    <Page className="font-poppins flex h-screen touch-none flex-col overflow-x-auto overflow-y-auto bg-white">
      <Header />
      <main className="container flex h-[calc(100vh-60px)] flex-col ">
        <PageTitle isAdmin={session.isAdmin || session.isBroker || session.isAdminViewer} />
        <div className="flex shrink grow-0 flex-col px-5">{getSelectedGrid()}</div>
      </main>
    </Page>
  )
}

export default () => (
  <Provider>
    <DashboardPage />
  </Provider>
)
