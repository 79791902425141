import * as E from 'effect/Either'
import { pipe } from 'effect/Function'
import { Collection, createCollection, CreateThunksReturn, initCollection } from '@skywatch/core'
import { Broker } from './types/broker'
import { getAllBrokersEndpoint } from '~/api/affiliate'
import { createBrokerEndpoint, deleteBrokerEndpoint, updateBrokerEndpoint } from '~/api/broker'
import { BrokerType } from '../forms/types/broker'

const fetchAllBrokers = async (affiliateKey: string) => {
  try {
    const res = await getAllBrokersEndpoint({ urlParams: { affiliateKey: affiliateKey } })
    return pipe(
      res,
      E.match({
        onLeft: error => {
          throw error
        },
        onRight: apiResponse => {
          const brokers = apiResponse.data.objects.map(b => Broker.fromApi(b))
          return {
            objects: brokers,
          }
        },
      })
    )
  } catch (e) {
    throw e
  }
}

const addBroker = async (affiliateKey: string, brokerData: BrokerType) => {
  try {
    const res = await createBrokerEndpoint({
      payload: Broker.toApi(brokerData),
      urlParams: { affiliateKey: affiliateKey },
    })

    return pipe(
      res,
      E.match({
        onLeft: error => {
          throw error
        },
        onRight: apiResponse => {
          const broker = Broker.fromApi(apiResponse.data)
          return broker
        },
      })
    )
  } catch (e) {
    throw e
  }
}

const deleteBroker = async (brokerId: number) => {
  try {
    const res = await deleteBrokerEndpoint({ urlParams: { userId: brokerId.toString() } })

    return pipe(
      res,
      E.match({
        onLeft: error => {
          throw error
        },
        onRight: _ => {
          return brokerId
        },
      })
    )
  } catch (e) {
    throw e
  }
}

const updateBroker = async (brokerId: string, brokerData: BrokerType) => {
  try {
    const res = await updateBrokerEndpoint({
      payload: Broker.toApi(brokerData),
      urlParams: { userId: brokerId },
    })

    return pipe(
      res,
      E.match({
        onLeft: error => {
          throw error
        },
        onRight: apiResponse => {
          const broker = Broker.fromApi(apiResponse.data)
          return broker
        },
      })
    )
  } catch (e) {
    throw e
  }
}

export default createCollection({
  name: 'brokers',
  initialState: initCollection<Collection<Broker>>(),
  asyncMethods: (thunks: CreateThunksReturn<Broker>) => ({
    fetchAllBrokers: thunks.upsertMany(fetchAllBrokers),
    addBroker: thunks.upsertOne(addBroker),
    deleteBroker: thunks.deleteOne(deleteBroker),
    updateBroker: thunks.upsertOne(updateBroker),
  }),
})
