import { Model } from '@skywatch/core'
import { AffiliateNewType } from '../../forms/types/affiliate'
import { AffiliateGridDataSchema } from '~/models/affiliate'
import { ApiAffiliate, ApiPostAffiliate } from '../../model'

export interface Affiliate extends Model<string> {
  name: string
  description?: string
  logoUrl?: string
  parentsKeys: string[]
  url?: string
  disabled: boolean
  registrationFee: boolean
  qouteFee: boolean
  newBusinessCommission: number
  renewalCommission: number
}

export const Affiliate = {
  fromApi: (data: ApiAffiliate): Affiliate => {
    return {
      id: data.id,
      logoUrl: data.logoUrl,
      name: data.name,
      description: data.description,
      parentsKeys: data.parentsKeys,
      url: data.url,
      disabled: data.disabled,
      registrationFee: data.registrationFee,
      qouteFee: data.qouteFee,
      newBusinessCommission: data.newBusinessCommission,
      renewalCommission: data.renewalCommission,
    }
  },
  toApi: (data: Affiliate): ApiAffiliate => {
    return {
      id: data.id,
      logoUrl: data.logoUrl,
      name: data.name,
      description: data.description,
      parentsKeys: data.parentsKeys,
      url: data.url,
      disabled: data.disabled,
      registrationFee: data.registrationFee,
      qouteFee: data.qouteFee,
      newBusinessCommission: data.newBusinessCommission,
      renewalCommission: data.renewalCommission,
    }
  },
  toPostApi: (data: AffiliateNewType): ApiPostAffiliate => {
    return {
      affiliateName: data.name,
      description: data.description,
      parentKey: data.network,
      admin: data.admin,
    }
  },
}

export interface AffiliateGrid extends Model<string> {
  affiliateName: string
  parentName?: string
  parentsKeys?: string[]
  disabled: boolean
}

export const AffiliateGrid = {
  fromApi: (data: AffiliateGridDataSchema): AffiliateGrid => {
    return {
      id: data.affiliateKey,
      affiliateName: data.affiliateName,
      parentName: data.parentName,
      parentsKeys: data.parentsKeys,
      disabled: data.disabled,
    }
  },
}

export type GetAllAffiliatesByQueryRequest = {
  limit: number
  page: number
  sortBy: string
  searchValue: string
  onlyAgencies: boolean
}
