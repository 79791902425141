import { useState } from 'react'
import { Button, useDialog } from '@skywatch/ui'
import { BackendError } from '@skywatch/core'
import { Wait, useErrorModal } from '@drive/shared'
import { useSession } from '@drive/auth'
import AffiliateForm from '../forms/AffiliateForm'
import { AffiliateType } from '../forms'
import { useAffiliate } from '.'

export default () => {
  const { state: affiliate, updateAffiliate, updateAffiliatePaymentFees } = useAffiliate()
  const { setOpen, Dialog, open } = useDialog()
  const [error, setError] = useState<BackendError>()
  const { setOpen: setOpenErrorModal, ErrorModal } = useErrorModal()
  const { isAdmin } = useSession()

  const onSubmit = async (values: AffiliateType) => {
    try {
      const res = await updateAffiliate(affiliate, values)
      if (isAdmin) {
        await updateAffiliatePaymentFees(res.id, values.paymentFees)
      }
      setOpen(false)
    } catch (e: any) {
      setError({ message: e.messages[0].messages[0].message, title: e.messages[0].messages[0].payload })
      setOpenErrorModal(true)
    }
  }

  const EditAffiliatePopup = () => (
    <>
      <ErrorModal errorText={error?.message || undefined} errorTitle={error?.title} />
      <Dialog>
        <AffiliateForm
          isEditMode={true}
          isAdmin={isAdmin}
          defaultValues={{
            name: affiliate.name,
            description: affiliate.description,
            paymentFees: {
              registrationFee: affiliate.registrationFee,
              quoteFee: affiliate.qouteFee,
              bindCommission: affiliate.newBusinessCommission !== 0 || affiliate.renewalCommission !== 0,
              newBusinessCommission: affiliate.newBusinessCommission,
              renewalCommission: affiliate.renewalCommission,
            },
          }}
          onSubmitForm={onSubmit}
        />
        <div className="mt-8 grid grid-cols-2 gap-3">
          <Button
            onClick={() => setOpen(false)}
            buttonType={'gray'}
            variant={'outline'}
            className="col-span-2 w-full md:col-span-1"
          >
            Cancel
          </Button>{' '}
          <Button type="submit" buttonType="primary" form="affiliate-form" className="col-span-2 w-full md:col-span-1">
            Save Changes
          </Button>{' '}
        </div>
        <Wait visible={affiliate.status === 'busy'} />
      </Dialog>
    </>
  )
  return { EditAffiliatePopup, setOpen, open }
}
